













































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import SubArrayForm from "../SubArrayForm.vue";
import FileInput from "../FileInput.vue";
import { Guid } from "guid-typescript";
import FormContainer from "../FormContainer.vue";
import { saveAs } from "file-saver";

@Component({
  components: {
    FileInput,
    FormContainer,
  },
})
export default class SettingsForm extends Vue {
  @Prop()
  public id!: string;

  public provider!: DataProvider<ActionType>;

  public crud!: ICrudClient<ActionType>;
  private file: any = null;

  private item: any = null;
  private rawFile: any = null;

  created() {
    this.provider = this.$service.providers.settings;
    this.crud = this.$service.crud.settings;
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        identifier: "",
        email: "",
        address: "",
        companyTitle: "",
        logo: "",
        isActive: false,
      };
    } else {
      this.file = {
        name: "logo",
      };
    }
  }
  download() {
    saveAs(this.item.logo, "logo");
  }
  update(evt: any) {
    this.item.logo = evt.base64Contents;
    this.file = evt;
  }
}
